import { Center, Box, Text, Container, Link } from "@chakra-ui/react";
import styled from "@emotion/styled";
import bg from "assest/cover-bg.svg";
import { FC, useCallback } from "react";

const CheckBtn = styled.button`
  outline: none;
  box-shadow: none;
  height: 56px;
  width: 216px;
  border-radius: 6px;
  background: transparent;
  box-shadow: 0 0 0 1px #ffffff;
  filter: drop-shadow(0px 4px 24px rgba(230, 0, 122, 0.2));
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0 0 0 2px #ffffff;
  }
`;

export const Cover: FC = () => {
  const scrollToSearch = useCallback(() => {
    const $ele = document.querySelector("#search");

    $ele?.scrollIntoView({
      block: "start",
      inline: "nearest",
      behavior: "smooth",
    });
  }, []);

  return (
    <Box backgroundImage={`url(${bg})`} backgroundSize="cover">
      <Container pt={["40px", 110]} pb={["36px", 93]} maxW={925}>
        <Text
          textAlign="center"
          color="white"
          mb={6}
          fontSize={[36, 56]}
          fontWeight={700}
          lineHeight={1.28}
          textShadow="0px 1px 25px 0px #645AFF1A"
        >
          Check your ACA & KAR
        </Text>
        <Text
          mb={3}
          textAlign="center"
          color="white"
          fontSize={[16, 20]}
          lineHeight={1.5}
          fontWeight={500}
          textShadow="0px 4px 24px 0px #E6007A33"
        >
          You may be entitled to ACA and/or KAR distribution from participating in Mandala Festivals, Build Acala
          events, Acala and Karura crowdloan, and other events.
        </Text>
        <Text
          textAlign="center"
          fontSize={[16, 20]}
          lineHeight={1.5}
          fontWeight={500}
          color="grey.6"
          textShadow="0px 4px 24px 0px #E6007A33"
        >
          If you have any questions about ACA and/or KAR, please contact us in our{" "}
          <Link href="https://discord.gg/xZfRD6rVfJ" target="_blank" rel="noreferrer" textDecoration="underline">
            Discord support channel
          </Link>
          .
        </Text>
        <Center mt={[4, 8]}>
          <CheckBtn onClick={() => scrollToSearch()}>CHECK HERE</CheckBtn>
        </Center>
      </Container>
    </Box>
  );
};
